@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400&display=swap");

:root {
	--dark: #04142b;
	--light: #ffffff;
}

.App {
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	text-align: center;
	flex-direction: column;
	color: var(--dark);
	background-color: var(--light);
	font-family: "Poppins", sans-serif;
	font-weight: 400;
}

.cardItem:last-child {
	left: 0;
}

a,
a:link,
a:visited,
a:focus,
a:hover,
a:active {
	color: var(--light);
	text-decoration: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
	font-weight: unset;
}

.vsplit {
	height: 100vh;
	width: 100vw;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex: 1;
}

.vleft {
	background-color: var(--dark);
}

.cardItem {
	padding: 0px 15px;
	width: 300px;
	border-radius: 15px;
	background-color: var(--dark);
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	flex: 0 1 auto;
	margin: 5px;
	color: var(--light);
	transition: 0.1s;
	transition-timing-function: ease-in-out;
	border: 0px solid var(--light);
}

.cardItem:hover {
	outline: 1px solid var(--dark);
	background-color: var(--light);
	color: var(--dark);
}

@keyframes wiggle {
	0% {
		transform: rotate(0deg);
	}
	85% {
		transform: rotate(0deg);
	}
	90% {
		transform: translate(2px, 0);
		/* transform: rotate(2deg); */
	}
	95% {
		transform: translate(-2px, 0);
		/* transform: rotate(-2deg); */
	}
	100% {
		transform: rotate(0deg);
	}
}

.cardItem:last-child {
	animation-name: wiggle;
	animation-duration: 2s;
	animation-iteration-count: infinite;
}

.cardTitle {
	flex: 1;
	flex-grow: 1;
}

.emoji {
	width: 20px;
	margin-left: 5px;
	/* margin-right: auto; */
}

@media only screen and (orientation: portrait) {
	.App {
		height: unset;
		padding-top: 25%;
	}
	.cardItem {
		padding: 0px 15px;
		width: 88%;
		border-radius: 15px;
		background-color: var(--dark);
		display: flex;
		flex-direction: row;
		align-items: center;
		justify-content: center;
		flex: 0 1 auto;
		color: var(--light);
		transition: 0.1s;
		transition-timing-function: ease-in-out;
		border: 0px solid var(--light);
	}
}

